export * from "./common";
export const generateExternalBuyUrl = url => {
    /**
     * If the external_buy_url is of another event on insider.in,
     * change the base URL to h5 to make it compatible in Paytm app
     */
    if (url.includes("//insider.in")) {
        return url.replace("//insider.in", "//h5.insider.in");
    }
    return url;
};
