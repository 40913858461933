/** @jsx jsx */
import { jsx } from "@emotion/core";
const PaytmLoader = ({ styles, primaryFill = "#22346A", secondaryFill = "#25B5E9" }) => (<svg css={styles} width="210" height="30" viewBox="0 0 210 30" xmlns="http://www.w3.org/2000/svg">
		<circle cx="15" cy="15" r="9" fill={primaryFill}>
			<animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;13;10.5;6.5;4;6.5;10.5;13;15" calcMode="linear" repeatCount="indefinite"/>
		</circle>
		<circle cx="60" cy="15" r="15" fill={primaryFill}>
			<animate attributeName="r" from="13" to="13" begin="0s" dur="0.8s" values="13;15;13;10.5;6.5;4;6.5;10.5;13" calcMode="linear" repeatCount="indefinite"/>
		</circle>
		<circle cx="105" cy="15" r="15" fill={primaryFill}>
			<animate attributeName="r" from="10.5" to="10.5" begin="0s" dur="0.8s" values="10.5;13;15;13;10.5;6.5;4;6.5;10.5" calcMode="linear" repeatCount="indefinite"/>
		</circle>
		<circle cx="150" cy="15" r="15" fill={secondaryFill}>
			<animate attributeName="r" from="6.5" to="6.5" begin="0.1s" dur="0.8s" values="6.5;10.5;13;15;13;10.5;6.5;4;6.5" calcMode="linear" repeatCount="indefinite"/>
		</circle>
		<circle cx="195" cy="15" r="15" fill={secondaryFill}>
			<animate attributeName="r" from="4" to="4" begin="0s" dur="0.8s" values="4;6.5;10.5;13;15;13;10.5;6.5;4" calcMode="linear" repeatCount="indefinite"/>
		</circle>
	</svg>);
export default PaytmLoader;
