import React from "react";
import PaytmURLError from "../PaytmURLError";
import * as Sentry from "@sentry/browser";
import { initSentry } from "../../helpers/misc/common";
class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { hasError: false };
    }
    componentDidMount() {
        initSentry(Sentry);
    }
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        Sentry.captureException(error);
    }
    render() {
        if (this.state.hasError) {
            return <PaytmURLError backNavFn={() => window.JSBridge.call("popWindow")}/>;
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
