import React from "react";
import PaytmLoader from "../PaytmLoader";
const Loader = ({ fullscreen }) => {
    let styles = {
        "min-height": "250px",
        position: "relative",
        display: "block",
        width: "100px",
        margin: "8vw auto"
    };
    if (fullscreen) {
        styles = Object.assign(styles, {
            height: "100vh",
            margin: "auto"
        });
    }
    return <PaytmLoader styles={styles}/>;
};
export default Loader;
