import React from "react";
import { Route, IndexRoute, Redirect } from "react-router";
import { Map } from "immutable";
import { generateAllVenuesLink, generateAllArtistsLink, generateSearchLink, generateSEOCategoryLink, generateSEOGroupLink, generateSEOGroupCategoryLink, generateSummerGroupLink, generateSEOEverythingLink, generateUserProfileBaseLink, generateUserProfileTicketsLink, generateUserProfilePassesLink, generateUserProfileFavouritesLink, generateUserProfileLink, generateUserProfileStashesLink } from "../helpers/linkGenerators";
import App from "../containers/InsiderAppWithSubscription";
// Load all our top level pages
import asyncPromoCodesListPage from "../pages/AsyncPromoCodesListPage";
import asyncAllArtistsHoc from "../pages/AsyncAllArtistsPage";
import asyncAllVenuesHoc from "../pages/AsyncAllVenuesPage";
import asyncArticleHoc from "../pages/AsyncArticlePage";
import asyncArtistHoc from "../pages/AsyncArtistPage";
import AsyncCancellationPage from "../pages/AsyncCancellationPage";
import asyncCategoryHoc from "../pages/AsyncCategoryPage";
import asyncCheckoutHoc from "../pages/AsyncCheckoutPage";
import asyncItemAddonHoc from "../pages/AsyncItemAddonPage";
import asyncClubHoc from "../pages/AsyncClubPage";
import asyncEventHoc from "../pages/AsyncEventPage";
import asyncEverythingHoc from "../pages/AsyncEverythingPage";
import asyncGoPageHoc from "../pages/AsyncGoPage";
import asyncGroupHoc from "../pages/AsyncGroupPage";
import asyncHomeHoc from "../pages/AsyncHomePage";
import AsyncLoginPage from "../pages/AsyncLoginPage";
import asyncEmailLoginHoc from "../pages/AsyncEmailLoginPage";
import AsyncProfilePage from "../pages/AsyncProfilePage";
import asyncSearchHoc from "../pages/AsyncSearchPage";
import AsyncSuccessPage from "../pages/AsyncPaymentSuccessPage";
import asyncVenueHoc from "../pages/AsyncVenuePage";
import AsyncMobileCitySelector from "../pages/AsyncCitySelectorPage";
import { checkIfSummerCity, isExternalHref } from "../helpers/misc";
import { TIMES } from "../helpers/constants";
import AsyncSignUpSuccessPage from "../pages/AsyncSignUpSuccessPage";
import asyncDynamicListHoc from "../pages/AsyncDynamicListPage";
import AsyncDigitalEventsSlackInvitePage from "../pages/AsyncDigitalEventsSlackInvitePage";
import AsyncPassDemoPage from "../pages/AsyncPassDemoPage";
import AsyncTicketDetailPage from "../pages/AsyncTicketDetailPage";
import asyncPaymentStatusHoc from "../pages/AsyncPaymentStatusPage";
import AsyncGoogleLoginPage from "../pages/AsyncGoogleLoginPage";
import AsyncPaytmLoginPage from "../pages/AsyncPaytmLoginPage";
import AsyncFreshdeskSSOPage from "../pages/AsyncFreshdeskSSOPage";
// import AsyncReportEventPage from "../pages/AsyncReportEventPage";
import AsyncContactPage from "../pages/AsyncContactPage";
import AsyncISLLayout from "../containers/ISLLayoutWithSubcription";
import AsyncISLLandingPage from "../pages/AsyncISLLandingPage";
import AsyncIPLLandingPage from "../pages/AsyncIPLLandingPage";
import AsyncIPLLayout from "../containers/IPLLayoutWithSubcription";
import AsyncISLMatchSchedulePage from "../pages/AsyncISLMatchSchedulePage";
import AsyncIPLMatchSchedulePage from "../pages/AsyncIPLMatchSchedulePage";
import AsyncISLTeamPage from "../pages/AsyncISLTeamPage";
import AsyncIPLTeamPage from "../pages/AsyncIPLTeamPage";
import AsyncDynamicEventLandingPage from "../pages/AsyncDynamicEventLandingPage";
import AsyncMicrositeEventLandingPage from "../pages/AsyncMicrositeEventLandingPage";
import AsyncNewYearEventLandingPage from "../pages/AsyncNewYearEventLandingPage";
import AsyncMumbaiEventLandingLayout from "../containers/MumbaiEventLandingLayoutWithSubscription";
import AsyncMumbaiEventLandingPage from "../pages/AsyncMumbaiEventLandingPage";
import AsyncMumbaiEventSchedulePage from "../pages/AsyncMumbaiEventSchedulePage";
import AsyncHoliEventLandingPage from "../pages/AsyncHoliEventLandingPage";
import AsyncYouTubeFanFestLandingPage from "../pages/AsyncYouTubeFanFestLandingPage";
import AsyncSchweppesLandingPage from "../pages/AsyncSchweppesLandingPage";
import AsyncAnnouncementPage from "../pages/AsyncAnnouncementPage";
import AsyncBacardiInsiderSignup from "../pages/AsyncBacardiInsiderSignup";
import asyncBuyPageHoc from "../pages/AsyncBuyPage";
import asyncBuyPageHandleRouteHoc from "../pages/AsyncBuyPageHandleRoute";
import asyncShowsListPageHoc from "../pages/AsyncShowsListPage";
import asyncItemGroupsListPageHoc from "../pages/AsyncItemGroupsListPage";
import asyncItemListPageHoc from "../pages/AsyncItemListPage";
// yay side effects
// uses router state to send events to GA
const handleRouterUpdate = function () {
    if (typeof window !== "undefined" && window.ga && !window.firstPageView) {
        ga("send", "pageview", `${this.state.location.pathname}${this.state.location.search}`);
    }
    if (window.firstPageView) {
        window.firstPageView = false;
    }
    if (typeof window !== "undefined" && window.fbq) {
        window.fbq("track", "PageView");
    }
    if (typeof window !== "undefined" && window.scrollTo) {
        window.scrollTo(0, 0);
    }
};
const generateCategoryRoutesForCity = (city, categories) => categories.map(category => (<Route key={`${city}-category-${category.get("name")}`} path={generateSEOCategoryLink(city, category.get("name"))} getComponent={asyncCategoryHoc(category)}/>));
const generateGroupRoutesForCity = (city, groups) => groups.map(group => (<Route key={`${city}-group-${group.get("name")}`} path={`${generateSEOGroupLink(city, group.get("name"))}(/:category)`} getComponent={asyncGroupHoc(group)}/>));
const generateTimeBasedEverythingRoutesForCity = (city, times) => {
    return times.map(time => {
        return (<Route key={`time-based-${city}-${time}-everything`} path={generateSEOEverythingLink(city, time)} getComponent={asyncEverythingHoc(time)}/>);
    });
};
const generateTimeBasedCategoryRoutesForCity = (city, categories, times) => {
    return times
        .map(time => {
        return categories.map(category => {
            return (<Route key={`time-based-${city}-${time}-${category.get("name")}`} path={generateSEOCategoryLink(city, category.get("name"), time)} getComponent={asyncCategoryHoc(category, time)}/>);
        });
    })
        .reduce((a, b) => {
        return a.concat(b);
    });
};
const generateTimeBasedGroupRoutesForCity = (city, groups, times) => {
    return times
        .map(time => {
        return groups.map(group => {
            return (<Route key={`time-based-${city}-${time}-group-${group.get("name")}`} path={`${generateSEOGroupLink(city, group.get("name"), time)}(/:category)`} getComponent={asyncGroupHoc(group, time)}/>);
        });
    })
        .reduce((a, b) => {
        return a.concat(b);
    });
};
/**
 * Creates all the (no of cities) x (no of groups + categories)
 * possible routes for the whole site
 * @param {string} selectedCity
 * @param {Immutable.List} groups
 * @param {Immutable.List} categories
 */
const getRoutes = (selectedCity, groups, categories) => {
    const monumentsGroup = new Map({
        name: "Monuments",
        icon_img: "https://media.insider.in/image/upload/c_crop,g_custom/v1516370357/olqxmr65z4xbfcefyl7z.jpg",
        display_details: {
            pick_description: "We've scoured the universe to bring you the best monuments",
            all_description: "Monuments",
            all_title: "Upcoming Monument Shows",
            pick_title: "Collections",
            colour: "#2633c0",
            seo_title: "Monuments in India. Explore and buy tickets online",
            seo_description: "Authorised ticketing partner for Archaeological Survey of India. Get m-ticket, walk-in directly, save time. Book online tickets to heritage sites and monuments."
        },
        type: "group"
    });
    const generateGroupRoutesForSelectedCity = generateGroupRoutesForCity.bind(null, selectedCity);
    const generateCategoryRoutesForSelectedCity = generateCategoryRoutesForCity.bind(null, selectedCity);
    const generateTimeBasedGroupRoutesForSelectedCity = generateTimeBasedGroupRoutesForCity.bind(null, selectedCity);
    const generateTimeBasedCategoryRoutesForSelectedCity = generateTimeBasedCategoryRoutesForCity.bind(null, selectedCity);
    const generateTimeBasedEverythingRoutesForSelectedCity = generateTimeBasedEverythingRoutesForCity.bind(null, selectedCity);
    return (<Route path="/" component={App}>
			{/* todo later move this go out / stay in selector */}
			<IndexRoute getComponent={asyncHomeHoc}/>

			{/* Home Route */}
			<Route key={`group-home`} path={`/${selectedCity}`} getComponent={asyncHomeHoc}/>

			{/* /cool-thing/article */}
			<Route path=":slug/article" getComponent={asyncArticleHoc}/>
			{/* /cool-thing/event */}
			<Route path=":slug/event" getComponent={asyncEventHoc}/>

			<Route path="online-events/:slug" key="online-events-tag-slug" getComponent={asyncDynamicListHoc("tag")}/>
			<Route path="events/:slug" key="events-tag-slug" getComponent={asyncDynamicListHoc("tag")}/>

			<Route path="brand/:slug" key="brand-events-by-slug" getComponent={asyncDynamicListHoc("brand")}/>

			{/* group routes */}
			{generateGroupRoutesForSelectedCity(groups)}
			{/* monuments group route */}
			<Route key={`${selectedCity}-group-${monumentsGroup.get("name")}`} path={`/monuments`} getComponent={asyncGroupHoc(monumentsGroup)}/>
			{/* category routes */}
			{generateCategoryRoutesForSelectedCity(categories)}

			{generateTimeBasedGroupRoutesForSelectedCity(groups, TIMES)}

			{generateTimeBasedCategoryRoutesForSelectedCity(categories, TIMES)}

			{generateTimeBasedEverythingRoutesForSelectedCity(TIMES)}

			{/* Handle redirects to older url structure */}
			<Redirect from="article/:slug" to=":slug/article"/>
			<Redirect from="event/:slug" to=":slug/event"/>
			<Redirect from="users/me/stashes" to="users/me/wallet"/>

			{/* /search?q=kool */}
			<Route path={generateSearchLink()} getComponent={asyncSearchHoc}/>

			<Route path={generateAllVenuesLink(selectedCity)} getComponent={asyncAllVenuesHoc}/>

			<Route path={generateAllArtistsLink()} getComponent={asyncAllArtistsHoc}/>

			<Route path=":slug/venue" getComponent={asyncVenueHoc}/>

			<Route path=":slug/artist" getComponent={asyncArtistHoc}/>

			<Route path=":slug/club" getComponent={asyncClubHoc}/>

			<Route path="/buy-page/addons" getComponent={asyncItemAddonHoc}/>

			<Route path="/buy/checkout" getComponent={asyncCheckoutHoc}/>

			<Route path="event/:slug/buy-page" getComponent={asyncBuyPageHoc}>
				<IndexRoute getComponent={asyncBuyPageHandleRouteHoc}/>
				<Route path="shows" getComponent={asyncShowsListPageHoc}/>
				<Route path="shows/:showId" getComponent={asyncItemGroupsListPageHoc}/>
				<Route path="shows/:showId/:itemGroupName" getComponent={asyncItemListPageHoc}/>
			</Route>

			<Route path="go/:slug" getComponent={asyncGoPageHoc}/>
			<Route path="go/:slug/:city" getComponent={asyncGoPageHoc}/>

			<Route path="/payments/success-rsvp" getComponent={AsyncSuccessPage}/>

			<Route path="/payments/success" getComponent={AsyncSuccessPage}/>

			<Route path="/payments/status(/fromOrders)/orderId/:orderId" getComponent={asyncPaymentStatusHoc}/>

			<Route path="/payments/status(/fromOrders)/orderId/:orderId/itemId/:itemId" getComponent={asyncPaymentStatusHoc}/>

			<Route path={generateUserProfileBaseLink()} key="base" getComponent={AsyncProfilePage}/>

			<Route path={generateUserProfileTicketsLink()} key="tickets" getComponent={AsyncProfilePage}/>

			<Route path={generateUserProfilePassesLink()} key="passes" getComponent={AsyncProfilePage}/>

			<Route path={generateUserProfileFavouritesLink()} key="favourites" getComponent={AsyncProfilePage}/>

			<Route path={generateUserProfileLink()} key="profile" getComponent={AsyncProfilePage}/>

			<Route path={generateUserProfileStashesLink()} key="stashes" getComponent={AsyncProfilePage}/>

			<Route path="/users/login" getComponent={AsyncLoginPage}/>

			<Route path="/users/email-login" getComponent={asyncEmailLoginHoc}/>

			<Route path="/users/me" getComponent={AsyncProfilePage}/>

			<Route path="/users/freshdesk" getComponent={AsyncFreshdeskSSOPage}/>

			<Route path="/users/cancellation/:transactionId" getComponent={AsyncCancellationPage}/>

			<Route path="city-selector" getComponent={AsyncMobileCitySelector}/>

			<Route path="/unlocked-pass" getComponent={AsyncPassDemoPage}/>
			<Route path="promo-codes" getComponent={asyncPromoCodesListPage}/>
			<Route path={"h5-sign-up-success"} getComponent={AsyncSignUpSuccessPage}/>
			<Route path={"digital-events/slack-community"} getComponent={AsyncDigitalEventsSlackInvitePage}/>

			<Route path="/users/tickets/:shortcode" getComponent={AsyncTicketDetailPage}/>

			<Route path="/users/login/google" getComponent={AsyncGoogleLoginPage}/>
			<Route path="/users/login/paytm" getComponent={AsyncPaytmLoginPage}/>

			{/* <Route path="/report-event" getComponent={AsyncReportEventPage} /> */}
			<Route path="/contact" getComponent={AsyncContactPage}/>

			<Route path="/isl-indian-super-league" component={AsyncISLLayout}>
				<IndexRoute getComponent={AsyncISLLandingPage}/>
				<Route path="/" getComponent={AsyncISLLandingPage}/>
				<Route path="schedule" getComponent={AsyncISLMatchSchedulePage}/>
				<Route path=":teamId" getComponent={AsyncISLTeamPage}/>
			</Route>
			<Route path="/ipl-indian-premier-league" component={AsyncIPLLayout}>
				<IndexRoute getComponent={AsyncIPLLandingPage}/>
				<Route path="/" getComponent={AsyncIPLLandingPage}/>
				<Route path="schedule" getComponent={AsyncIPLMatchSchedulePage}/>
				<Route path=":teamId" getComponent={AsyncIPLTeamPage}/>
			</Route>

			<Route path="/anuv-jain-guldasta-tour-2023" getComponent={AsyncDynamicEventLandingPage}/>
			<Route path="/holi-events-in-india" getComponent={AsyncHoliEventLandingPage}/>
			<Route path="/ytff-youtube-fanfest-india-2024" getComponent={AsyncYouTubeFanFestLandingPage}/>
			<Route path="/schweppes-born-social-club" getComponent={AsyncSchweppesLandingPage}/>
			<Route path="/announcement" getComponent={AsyncAnnouncementPage}/>
			<Route path="/be-a-bacardi-insider" getComponent={AsyncBacardiInsiderSignup}/>
			{/* <Route path="/new-years-events" getComponent={AsyncNewYearEventLandingPage} /> */}
			<Route path="/microsite/:slug" getComponent={AsyncMicrositeEventLandingPage}/>

			{/* <Route path="/mumbai-festival-2024" component={AsyncMumbaiEventLandingLayout}>
            <IndexRoute getComponent={AsyncMumbaiEventLandingPage} />
            <Route path="/" getComponent={AsyncMumbaiEventLandingPage} />
            <Route path="schedule" getComponent={AsyncMumbaiEventSchedulePage} />
        </Route> */}

			{/* Catch all route, everything else is treated as an article */}
			{/* <Route path=":slug" getComponent={asyncArticleHoc} /> */}
		</Route>);
};
export { getRoutes, handleRouterUpdate };
