/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useEffect } from "react";
import { getJSBridge, isH5BridgeReady } from "../helpers/h5";
import { colors } from "../styles/common";
import { PaytmURLErrorIcon } from "./ui/icons/PaytmURLErrorIcon";
const defaultHeader = "It's not you, it's us!";
const defaultSubheader = "We failed to load the page.";
const errorWrapper = `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    min-height: 90vh;
    justify-content: center;
`;
const headerWrapper = `
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;
const header = `
    font-size: 16px;
    font-weight: 700;
`;
const subHeader = `
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
`;
const buttonContainer = `
    display: flex;
    flex-direction: column;
    width: 85%;
    position: fixed;
    bottom: 40px; 
    font-size: 14px;
    align-items: center;
    font-weight: 700;
`;
const tryAgainButton = `
    color: white;
    background-color: ${colors["paytmBlue"]};
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
    border-radius: 6px;
`;
const handleBackNavigation = (backNavFn) => {
    isH5BridgeReady().then(() => {
        backNavFn();
    });
};
const PaytmURLError = (props) => {
    const { backNavFn } = props;
    useEffect(() => {
        isH5BridgeReady().then(() => {
            const JSBridge = getJSBridge();
            JSBridge.subscribe("back", function () {
                handleBackNavigation(backNavFn);
            });
        });
    }, []);
    return (<div css={css(errorWrapper)}>
			<PaytmURLErrorIcon />
			<div css={css(headerWrapper)}>
				<div css={css(header)}>{defaultHeader}</div>
				<div css={css(subHeader)}>{defaultSubheader}</div>
			</div>
			<div css={css(buttonContainer)}>
				<button onClick={() => window.location.reload()} css={css(tryAgainButton)}>
					Retry
				</button>
			</div>
		</div>);
};
export default PaytmURLError;
