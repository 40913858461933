import React from "react";
export class PaytmURLErrorIcon extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const { className } = this.props;
        return (<svg width="145" height="145" viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
				<path d="M72.5 145C112.541 145 145 112.541 145 72.5C145 32.4594 112.541 0 72.5 0C32.4594 0 0 32.4594 0 72.5C0 112.541 32.4594 145 72.5 145Z" fill="#F3F8FF"/>
				<path d="M26.0131 57.4185L29.7179 60.0904C29.8641 60.1959 29.9624 60.355 29.9912 60.533C30.0201 60.7109 29.977 60.893 29.8716 61.0391L27.1996 64.7439C27.0955 64.8883 26.9383 64.9853 26.7627 65.0138C26.587 65.0422 26.4072 64.9997 26.2629 64.8956L22.5511 62.2186C22.405 62.1132 22.3066 61.954 22.2778 61.7761C22.249 61.5982 22.2921 61.4161 22.3975 61.2699L25.0795 57.5512C25.185 57.405 25.3441 57.3067 25.5221 57.2779C25.7 57.2491 25.8821 57.2921 26.0282 57.3975L26.0131 57.4185Z" fill="#97E591"/>
				<path d="M123.241 31.8783L119.767 29.3724C119.482 29.1667 119.084 29.2312 118.878 29.5163L116.372 32.9908C116.167 33.276 116.231 33.6738 116.516 33.8795L119.991 36.3854C120.276 36.5911 120.674 36.5266 120.879 36.2415L123.385 32.7669C123.591 32.4818 123.526 32.0839 123.241 31.8783Z" fill="#C0F9FF"/>
				<path d="M124.617 88.0639L130.713 86.8282C131.649 86.6384 132.253 85.7259 132.064 84.79L130.828 78.6946C130.638 77.7587 129.726 77.1538 128.79 77.3435L122.694 78.5792C121.759 78.769 121.154 79.6815 121.343 80.6173L122.579 86.7128C122.769 87.6487 123.681 88.2536 124.617 88.0639Z" fill="#FC75AC"/>
				<path d="M28.0194 102.039C29.687 102.039 31.0388 100.687 31.0388 99.0194C31.0388 97.3518 29.687 96 28.0194 96C26.3518 96 25 97.3518 25 99.0194C25 100.687 26.3518 102.039 28.0194 102.039Z" fill="#FFCF36"/>
				<path d="M30.2676 111.51H105.347" stroke="#07448E" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M80.1879 111.509H55.427C50.5887 111.509 46.718 107.589 46.718 102.815V37.6942C46.718 32.8641 50.6456 29 55.427 29H80.1879C85.0263 29 88.8969 32.9209 88.8969 37.6942V102.815C88.8969 107.589 84.9693 111.509 80.1879 111.509Z" fill="white"/>
				<path d="M49.6779 105.77V40.6491C49.6779 35.819 53.6055 31.9549 58.3869 31.9549H83.1478C84.457 31.9549 85.6524 32.2391 86.7339 32.7505C85.197 30.9321 82.8063 29.7388 80.1879 29.7388H55.427C50.5887 29.7388 46.718 33.6597 46.718 38.433V103.554C46.718 107.077 48.8241 110.089 51.841 111.453C50.5318 109.918 49.6779 107.93 49.6779 105.77Z" fill="#E6F0FF"/>
				<path d="M80.1879 111.509H55.427C50.5887 111.509 46.718 107.589 46.718 102.815V37.6942C46.718 32.8641 50.6456 29 55.427 29H80.1879C85.0263 29 88.8969 32.9209 88.8969 37.6942V102.815C88.8969 107.589 84.9693 111.509 80.1879 111.509Z" stroke="#07448E" stroke-width="3" stroke-miterlimit="10" stroke-linejoin="round"/>
				<path d="M72.7899 112.987L89.3541 84.3473C90.151 82.9835 92.0863 82.9835 92.8833 84.3473L109.447 112.987C110.244 114.351 109.277 115.999 107.683 115.999H74.5545C73.0176 116.055 72.0499 114.351 72.7899 112.987Z" fill="#FF9797" stroke="#07448E" stroke-width="3" stroke-miterlimit="10" stroke-linejoin="round"/>
				<path d="M91.1758 93.2119V105.259" stroke="#07448E" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M91.1189 110.714C91.8735 110.714 92.4852 110.103 92.4852 109.35C92.4852 108.597 91.8735 107.986 91.1189 107.986C90.3644 107.986 89.7527 108.597 89.7527 109.35C89.7527 110.103 90.3644 110.714 91.1189 110.714Z" fill="#07448E"/>
				<path d="M55.3699 55.594L61.2334 61.5606" stroke="#07448E" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M61.2903 55.6512L55.313 61.5042" stroke="#07448E" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M74.0972 55.594L80.0176 61.5606" stroke="#07448E" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M80.0745 55.6512L74.0972 61.5042" stroke="#07448E" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M59.1838 80.1423C59.1838 80.1423 67.3805 69.857 76.0896 80.1423" stroke="#07448E" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>);
    }
}
