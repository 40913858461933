import { postToSite, postFormToSite } from "./common";
import { getEncryptedSsoToken } from "../auth";
import { getDeviceInfo, setPlatformFromSession } from "../misc";
import { getAppInfo, getDeviceManufacturer, fetchPaytmAuthToken, getEncryptedSsoToken as fetchToken, encryptSSOToken } from "../h5";
import { memoize } from "../inMemoryCache";
const getRefSource = memoize(async () => {
    const { os } = await getDeviceInfo();
    const { appVersion, h5Version, appVersionName, appVersionCode } = await getAppInfo();
    // ios sends appVersion and android appVersionName
    const manufacturer = await getDeviceManufacturer();
    return `${os}|${appVersion || appVersionName}|${appVersionCode || ""}|${h5Version}|${manufacturer}`;
});
export const postFormToSiteWithEncryptedSsoToken = async function (resource, formData) {
    //fetched from store
    const encryptedSsoTokenFromStore = getEncryptedSsoToken();
    // fetch unencrypted sso token from bridge from `PaytmAuthHandler`
    const sso_token = await fetchPaytmAuthToken();
    // encrpt the token via encryption API
    const sso_token_encr = await encryptSSOToken(sso_token);
    if (encryptedSsoTokenFromStore !== sso_token_encr) {
        console.log("Different encrypted sso tokens");
        // TODO: push to sentry
    }
    formData.device_platform = setPlatformFromSession();
    formData.encryptedSsoToken = sso_token_encr;
    formData.ssoToken = sso_token;
    formData.refSource = await getRefSource();
    return postFormToSite(resource, formData);
};
export const postToSiteWithEncryptedSsoToken = async function (resource, body) {
    if (!body) {
        body = {};
    }
    const encryptedSsoToken = getEncryptedSsoToken();
    const sso_token = await fetchPaytmAuthToken();
    body.device_platform = setPlatformFromSession();
    body.encryptedSsoToken = encryptedSsoToken;
    body.ssoToken = sso_token;
    body.refSource = await getRefSource();
    const stringifiedBody = body ? JSON.stringify(body) : "";
    return postToSite(resource, stringifiedBody);
};
export const paytmCheckout = async (transactionId) => {
    const sso_token = await fetchPaytmAuthToken();
    const device_platform = setPlatformFromSession();
    return postFormToSite("/cart/checkoutPaytmCart", { transactionId, sso_token, device_platform });
};
export * from "./common";
