import { getEncryptedSsoToken, getOS, getPaytmStoreUserData, getUserData, login as paytmLogin, navigateTo, paytmDismissLoader, saveData, share, getAppInfo, appVersionCheck } from "../h5";
import { memoize } from "../inMemoryCache";
import { CLEVERTAP_ACTIONS, paytmBlacklistTagId } from "../constants";
import { loginWithPaytmApi, logout as insiderLogout } from "../apiClient";
import { fromJS } from "immutable";
import { sendClevertapOnUserLoginEvent } from "./clevertap";
import { sendClevertapEvent } from "./clevertap.paytm";
import { checkPhoenixViaUserAgent } from "../appBridge";
export const hideLoader = async () => {
    const elem = document.getElementById("init-loader");
    if (elem) {
        elem.style.display = "none";
    }
    await paytmDismissLoader();
};
export const getDeviceInfo = memoize(() => {
    return getOS().then(os => {
        return { os };
    });
});
export const stripHrefDomain = (href) => {
    if (!href || !href.startsWith) {
        return href;
    }
    const sources = ["https://insider.in", "http://insider.in"];
    for (const source of sources) {
        if (href.startsWith(source)) {
            return href.substring(source.length);
        }
    }
    return href;
};
const toDataURL = (url) => fetch(url)
    .then(response => response.blob())
    .then((blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
}));
export const nativeShare = ({ text, url, imageUrl, onSuccess, onError, fallback }) => {
    const navigator = window.navigator;
    const shareObject = {
        text: text + " : " + url,
        title: "Share this event! The more the merrier, right?",
        subject: "",
        imageBase64: Buffer.from(imageUrl).toString("base64")
    };
    if (checkPhoenixViaUserAgent()) {
        share(shareObject)
            .then((result) => {
            if (!result || (result && !result.data.success))
                fallback && fallback();
        })
            .catch(() => {
            fallback && fallback();
        });
    }
    else if (navigator.share) {
        return navigator
            .share({ text, url })
            .then(onSuccess)
            .catch(onError);
    }
    else {
        fallback && fallback();
    }
};
export function getAlgoliaEventSearchFilters(cityTagId) {
    const date = Math.floor(Date.now() * 0.001);
    return [
        `endTime > ${date}`,
        `AND (NOT tagids:${paytmBlacklistTagId} )`,
        `AND (NOT group_id.name:"Amusement Parks" )`,
        `AND ( tagids:${cityTagId}<score=1> OR NOT tagids:${cityTagId}<score=0> )`
    ].join(" ");
}
export function getAlgoliaArticleSearchFilters() {
    return [`( NOT tagids:${paytmBlacklistTagId} )`].join(" ");
}
export const isLoggedInOnPaytm = (customerId) => {
    // Although we should check if an encryptedSsoToken exists some apps dont fire the callback when the user is not logged in.
    // So we are using the existence of customerId instead.
    // Note that some versions of IOS app return a customerId even if the user is not logged in if the user uninstalls
    // an older version of the app in which they were logged in and installs the affected version.
    return !!customerId;
};
export const isUserChanged = async (userDataForCurrentPaytmSession, userDataFromPaytmStore) => {
    userDataForCurrentPaytmSession = userDataForCurrentPaytmSession || (await getUserData());
    userDataFromPaytmStore = userDataFromPaytmStore || (await getPaytmStoreUserData());
    return !(userDataFromPaytmStore && userDataForCurrentPaytmSession.customerId === userDataFromPaytmStore.customerId);
};
export async function loginWithPaytm(referrerName) {
    let userDataFromPaytmSession = await getUserData();
    let encryptedSsoToken = await getEncryptedSsoToken();
    let userDataFromPaytmStore = await getPaytmStoreUserData();
    const isUserLoggedInOnPaytm = isLoggedInOnPaytm(userDataFromPaytmSession.customerId);
    if (!isUserLoggedInOnPaytm) {
        sendClevertapEvent(CLEVERTAP_ACTIONS.LIVE_EVENT_OPEN_PAYTM_LOGIN_MODAL, {
            "paytm store customerId": userDataFromPaytmStore && userDataFromPaytmStore.customerId
        });
        // show paytm login modal
        const isPaytmLoginSuccess = await paytmLogin();
        if (!isPaytmLoginSuccess) {
            return;
        }
        else {
            userDataFromPaytmSession = await getUserData();
            encryptedSsoToken = await getEncryptedSsoToken();
        }
    }
    const hasUserChanged = await isUserChanged(userDataFromPaytmSession, userDataFromPaytmStore);
    if (hasUserChanged) {
        let { customerId, phoneNumber, emailId, firstName, lastName } = userDataFromPaytmSession;
        // if the user already had a insider session... logout
        if (userDataFromPaytmStore && userDataFromPaytmStore.customerId) {
            await insiderLogout(true);
        }
        const result = await loginWithPaytmApi(customerId, firstName, lastName);
        const userInfo = {
            email: emailId,
            first_name: firstName,
            last_name: lastName,
            phone_no: phoneNumber,
            customerId,
            _id: result.getIn(["userData", "_id"]),
            apiKey: result.getIn(["userData", "apiKey"]),
            secret: result.getIn(["userData", "secret"]),
            encryptedSsoToken: encryptedSsoToken
        };
        try {
            await saveData(userInfo);
            sendClevertapOnUserLoginEvent(userInfo, {
                action: "login",
                method: "paytm",
                referrerName
            });
            return fromJS(userInfo);
        }
        catch (_a) {
            console.error("could not save data in paytm store, Login failed");
            return;
        }
    }
    // always use fresh encryptedSsoToken
    userDataFromPaytmStore.encryptedSsoToken = encryptedSsoToken;
    return fromJS(userDataFromPaytmStore);
}
export function navigateToPayment(paymentData) {
    if (paymentData) {
        const paytmCheckoutResponse = paymentData.get("paytmCheckoutResponse").toJS();
        const totalPrice = paymentData.getIn(["paytmCheckoutResponse", "TXN_AMOUNT"], "").toString();
        return Promise.all([getAppInfo(), getOS()])
            .then(([appInfo, platform]) => {
            const navigateToPage = appVersionCheck(appInfo, "10.32") && platform === "ios" ? "payment" : "paymentGatewayPage";
            return navigateTo(navigateToPage, {
                totalPrice,
                data: JSON.stringify(paytmCheckoutResponse),
                isNativePGFlow: true
            });
        })
            .catch(error => {
            console.error("Error occured while navigating to payment gateway", error);
        });
    }
}
export const getCartCommittedScreen = (paymentOptions, _checkoutWizard) => paymentOptions;
const getPaytmProductIdsFromCart = (cart) => {
    return cart
        .get("items")
        .filter(x => x.get("paytm_product_id"))
        .map(x => x.get("paytm_product_id"))
        .toSet();
};
export const getPromoCodesIfRequired = (props, prevProps) => {
    var _a;
    if (!prevProps)
        return;
    const userChanged = props.userData.getIn(["profile", "_id"]) !== prevProps.userData.getIn(["profile", "_id"]);
    const doesPromoExist = (_a = props === null || props === void 0 ? void 0 : props.getIsPaytmPromoCalled) !== null && _a !== void 0 ? _a : false;
    const prevProductIds = getPaytmProductIdsFromCart(prevProps.cart);
    const currProductIds = getPaytmProductIdsFromCart(props.cart);
    const cartItemsAdded = currProductIds.subtract(prevProductIds).size > 0;
    if (cartItemsAdded || userChanged || !doesPromoExist) {
        return props.fetchPaytmPromoCodes(currProductIds.toArray());
    }
};
export const generateH5Email = (customerId) => `${customerId}@paytm.h5`;
export const isH5Build = true;
export const setPlatformFromSession = () => {
    let platform = 'paytm';
    return platform;
};
export * from "./common";
export * from "./clevertap";
