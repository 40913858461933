import { toggleCitySelector, updateSelectedCity, fetchGooutHomeResources, fetchGroupsCategories, setIsSelectedCityInferred, syncExpressLoginInfo } from "../actions/index";
const dispatchInitialActions = (store, selectedCity, shouldShowCitySelector, isCityInferred) => {
    store.dispatch(fetchGroupsCategories());
    store.dispatch(fetchGooutHomeResources(selectedCity));
    store.dispatch(updateSelectedCity(selectedCity));
    store.dispatch(toggleCitySelector(shouldShowCitySelector));
    store.dispatch(setIsSelectedCityInferred(isCityInferred));
    store.dispatch(syncExpressLoginInfo());
};
export { dispatchInitialActions };
